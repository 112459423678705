import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import clsx from 'clsx';
import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';

import { ShouldRender } from '../../../shared/basic/ShouldRender';

export interface HomepageSliderProps {
  children: ReactNode;
  heading: string;
  isBook?: boolean;
  onPagination?: () => void;
  setIsPaginateShift?: (value: boolean) => void;
  isPaginateShift?: boolean;
  loading?: boolean;
}

const HomepageSlider: FC<HomepageSliderProps> = ({
  children,
  heading,
  isBook = false,
  onPagination,
  isPaginateShift = false,
  setIsPaginateShift,
  loading = false,
}) => {
  const sliderRef = useRef<HTMLDivElement | null>(null);
  const [isAtLeftmost, setIsAtLeftmost] = useState(true);
  const [isAtRightmost, setIsAtRightmost] = useState(false);
  const [windowWidth, setWindowWidth] = useState<number | undefined>(undefined);
  const [leftPadding, setLeftPadding] = useState<number>(44);
  const [onHover, setOnHover] = useState(false);
  // Initial left padding

  const screens = useBreakpoint();

  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  const scrollLeft = () => {
    sliderRef.current?.scrollBy({
      left: -600,
      behavior: 'smooth',
    });
    // Reset left padding when scrolling left
    setLeftPadding(12);
  };

  const scrollRight = () => {
    sliderRef.current?.scrollBy({
      left: 600,
      behavior: 'smooth',
    });
    // Set left padding to 0 when scrolling right
    setLeftPadding(0);
  };

  useEffect(() => {
    setTimeout(() => {
      setWindowWidth(window.innerWidth);

      // Update scroll position to prevent initial auto-scrolling
      if (sliderRef.current) {
        sliderRef.current.scrollLeft = 0;
      }

      const handleScroll = () => {
        if (sliderRef.current) {
          const isLeftmost = sliderRef.current.scrollLeft === 0;
          const isRightmost =
            sliderRef.current.scrollLeft + sliderRef.current.clientWidth + 1 >=
            sliderRef.current.scrollWidth;

          setIsAtLeftmost(isLeftmost);
          setIsAtRightmost(isRightmost);
        }
      };

      sliderRef.current?.addEventListener('scroll', handleScroll);

      window.addEventListener('resize', updateWindowWidth);

      return () => {
        sliderRef.current?.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', updateWindowWidth);
      };
    }, 0);
  }, []);

  // Check if the total width of the row of cards exceeds the window width
  const isExceedingWindowWidth =
    windowWidth !== undefined &&
    sliderRef.current?.scrollWidth !== undefined &&
    windowWidth < sliderRef.current?.scrollWidth;

  useEffect(() => {
    if (isAtRightmost && onPagination) {
      onPagination();
      setIsPaginateShift && setIsPaginateShift(true);
    }
  }, [isAtRightmost]);

  useEffect(() => {
    if (isPaginateShift) {
      // scrollRight();
      setIsPaginateShift && setIsPaginateShift(false);
    }
  }, [isPaginateShift]);

  return (
    <div
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
      className='text-fadedWhite'
    >
      <div className='flex justify-between mb-2 '>
        <ShouldRender check={loading}>
          <h2 className='text-lg md:text-xl xl:text-2xl font-semibold capitalize pl-2 md:px-5 lg:px-12'>
            {heading}
          </h2>
        </ShouldRender>

        {/* <ShouldRender check={screens?.lg && isExceedingWindowWidth}>
          <div className='flex '>
            <div className=' h-full transform top-0'>
              <div className='flex items-center h-full justify-center px-2 md:px-4'>
                <button
                  disabled={isAtLeftmost}
                  onClick={scrollLeft}
                  className='z-10 w-8 h-8 xl:w-10 xl:h-10 rounded-full  disabled:cursor-not-allowed    flex cursor-pointer justify-center bg-bgColor border border-bgDarkGray items-center disabled:text-gray-500 text-fadedWhite'
                >
                  <ChevronLeftIcon className=' w-4 h-4 xl:w-5 xl:h-5 ' />
                </button>
              </div>
            </div>
            <div className=' h-full transform top-0 right-0'>
              <div className='flex items-center h-full justify-center px-2 md:px-4'>
                <button
                  disabled={isAtRightmost}
                  onClick={scrollRight}
                  className='z-10 w-8 h-8 xl:w-10 xl:h-10 rounded-full  disabled:cursor-not-allowed    flex cursor-pointer justify-center bg-bgColor border border-bgDarkGray items-center disabled:text-gray-500 text-fadedWhite'
                >
                  <ChevronRightIcon className=' w-4 h-4 xl:w-5 xl:h-5 ' />
                </button>
              </div>
            </div>
          </div>
        </ShouldRender> */}
      </div>
      <ShouldRender check={screens?.lg}>
        <div
          style={{ scrollBehavior: 'smooth', scrollbarWidth: 'none' }}
          className='flex relative'
        >
          <div className='absolute z-30  h-full transform top-0 left-0'>
            <ShouldRender check={onHover && !isAtLeftmost}>
              <div className='relative h-full z-10'>
                <div className='absolute h-full transform left-0 backdrop-blur-sm rounded-r-2xl'>
                  <div className='flex items-center h-full justify-center   px-2 md:px-8 opacity-40 rounded-r-2xl bg-bgColor '></div>
                </div>
                {/* Button */}
                <button
                  disabled={isAtLeftmost}
                  onClick={scrollLeft}
                  className={clsx(
                    isBook ? 'top-[45%]' : 'top-[40%]',
                    'z-10 absolute left-0   w-8 h-8 xl:w-10 xl:h-10 rounded-full disabled:cursor-not-allowed flex justify-center items-center disabled:text-gray-500 text-fadedWhite transition-transform duration-300 ease-in-out cursor-pointer hover:scale-125'
                  )}
                >
                  <ChevronLeftIcon className='w-7 h-7 xl:w-8 xl:h-8' />
                </button>
              </div>
            </ShouldRender>
          </div>
          <div className='w-full relative'>
            <span
              ref={sliderRef}
              className='flex px-2 md:px-5 lg:px-12 hide-scrollbar'
              style={{
                scrollBehavior: 'smooth',
                scrollbarWidth: 'none',
                paddingLeft: `${isAtLeftmost ? 44 : leftPadding}px`,
                paddingRight: `${isAtRightmost && 44}px`,
              }}
            >
              {children}
            </span>

            <ShouldRender check={!isAtRightmost && isExceedingWindowWidth}>
              <div className='absolute  z-0 bg-gradient-to-l from-bgColor via-transparent to-transparent h-full w-10  right-0 top-0'></div>
            </ShouldRender>
          </div>
          <div className='absolute  h-full transform top-0 right-0'>
            <ShouldRender
              check={onHover && !isAtRightmost && isExceedingWindowWidth}
            >
              <div className='relative h-full '>
                <div className='absolute h-full transform right-0 backdrop-blur-sm rounded-l-2xl'>
                  <div className='flex items-center h-full justify-center px-2 md:px-8 opacity-40 rounded-l-2xl bg-bgColor'></div>
                </div>
                {/* Button */}
                <button
                  disabled={isAtRightmost}
                  onClick={scrollRight}
                  className={clsx(
                    isBook ? 'top-[45%]' : 'top-[40%]',
                    'z-10 absolute right-4  w-8 h-8 xl:w-10 xl:h-10 rounded-full disabled:cursor-not-allowed flex justify-center items-center disabled:text-gray-500 text-fadedWhite transition-transform duration-300 ease-in-out cursor-pointer hover:scale-125'
                  )}
                >
                  <ChevronRightIcon className='w-7 h-7 xl:w-8 xl:h-8' />
                </button>
              </div>
            </ShouldRender>
          </div>
        </div>
      </ShouldRender>
      <ShouldRender check={!screens?.lg}>
        <div
          ref={sliderRef}
          className='flex px-2 md:px-5  lg:px-12 hide-scrollbar'
          style={{ scrollBehavior: 'smooth', scrollbarWidth: 'none' }}
        >
          {children}
        </div>
      </ShouldRender>
    </div>
  );
};

export default HomepageSlider;
