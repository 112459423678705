import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { Carousel, Skeleton } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import clsx from 'clsx';
import { debounce } from 'lodash';
import Image, { StaticImageData } from 'next/image';
import { useRouter } from 'next/router';
import React, { useCallback, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { user } from '../../../../atoms/user';
import {
  EventDetails,
  logEvent,
} from '../../../../hooks/useClickEventTracking';
import { User } from '../../../../utils/interfaces/User';
import ButtonDefault, { ButtonVariants } from '../../../shared/basic/button';
import { ShouldRender } from '../../../shared/basic/ShouldRender';

interface BookImageProps {
  book: string | StaticImageData;

  handleImageLoad: () => void;
}

export interface BookProps {
  id: number;
  name: string;
  webFlowLink?: string;
  link?: string;
  desc: string;
  imageLG: StaticImageData;
  imageSM: StaticImageData;
  slug?: boolean;
}

type CustomCarouselRef = {
  next: () => void;
  prev: () => void;
  goTo: (slide: number) => void;
  autoPlay: (playType?: 'update' | 'leave' | 'blur' | undefined) => void;
  innerSlider: {
    goTo: (slide: number) => void;
  };
};
const BookImage = React.memo(({ book, handleImageLoad }: BookImageProps) => {
  return (
    <Image
      src={book}
      alt='BookImage'
      width={0}
      height={0}
      sizes='100vw'
      priority
      onLoad={handleImageLoad}
      style={{ width: '100%', height: 'auto' }}
      className='md:object-contain md:rounded-2xl'
    />
  );
});
const HeroSection: React.FC<{ books: BookProps[]; isSchool?: boolean }> = ({
  books,
  isSchool = false,
}) => {
  const [hoveredBookId, setHoveredBookId] = useState<number>(0);
  const [isHovered, setIsHovered] = useState(false);
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);
  const userData: User | undefined = useRecoilValue(user);
  const carouselRef = useRef<CustomCarouselRef | null>(null);
  const screens = useBreakpoint();
  const router = useRouter();

  const handleSlideChange = useCallback((currentSlide: number) => {
    setHoveredBookId(currentSlide);
  }, []);

  const handleMouseEnter = useCallback(
    debounce((index) => {
      setHoveredBookId(index);
      carouselRef.current?.goTo(index);
    }, 300),
    []
  );

  const handleImageLoad = () => {
    if (!allImagesLoaded) {
      setAllImagesLoaded(true);
    }
  };

  const getSourceValue = (path: string): string => {
    if (path === '/') {
      return 'home';
    } else if (path.includes('books')) {
      return 'books';
    } else if (path.includes('courses')) {
      return 'courses';
    } else if (path.includes('schools')) {
      return 'schools';
    } else if (path.includes('live')) {
      return 'live';
    } else {
      return 'home'; // Default value if no match
    }
  };

  return (
    <div
      className='carousel-container'
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className='relative '>
        <ShouldRender check={screens.lg && books?.length > 1}>
          <div className='flex absolute cursor-pointer z-30  bottom-[50%] -left-4 transform h-28 -translate-y-[-50%]  lg:translate-y-[50%] items-center next-arrow'>
            <button
              name='back'
              aria-label='Go back'
              onClick={() => {
                handleMouseEnter(hoveredBookId - 1);
              }}
              className='z-10 w-8 h-8 xl:w-9 xl:h-9 rounded-full  disabled:cursor-not-allowed    flex cursor-pointer justify-center bg-gray-500 bg-opacity-40 items-center text-fadedWhite   backdrop-filter backdrop-blur-md   '
            >
              <ChevronLeftIcon className=' w-4 h-4 xl:w-6 xl:h-6' />
            </button>
          </div>
        </ShouldRender>

        <div className=' z-10'>
          <ShouldRender check={books?.length}>
            <Carousel
              afterChange={handleSlideChange}
              initialSlide={hoveredBookId}
              autoplay={!isHovered}
              infinite={true}
              dots={screens.lg ? false : true}
              arrows={true}
              style={{ height: '100%' }}
              className={clsx(
                !allImagesLoaded && 'hidden',
                'lg:border-2 border-bgDarkGray lg:rounded-2xl overflow-hidden'
              )}
              ref={carouselRef as React.RefObject<CustomCarouselRef>}
            >
              {books.map((book) => (
                <div
                  key={book.id}
                  className={clsx('flex justify-center  w-full')}
                >
                  <div
                    onClick={() => {
                      if (!isSchool) {
                        if (window !== undefined && book?.webFlowLink) {
                          window.open(book.webFlowLink, '_blank');
                        }
                        if (window !== undefined && book?.link && !isSchool) {
                          router.push(book.link);
                        }
                      }
                      const eventParams: EventDetails = {
                        event_name: 'banner_view',
                        source: getSourceValue(router.pathname),
                        source_type: '',
                        source_id: null,
                        sub_source: null,
                        sub_source_id: null,
                        unit: 'boolean',
                        value: 1,
                      };
                      logEvent({
                        eventDetails: eventParams,
                        userData: userData,
                        pathname: router.pathname,
                      });
                    }}
                    className='flex relative cursor-pointer w-full lg:w-full items-center flex-shrink-0 max-h-[270px] lg:max-h-[400px]  xl:max-h-[450px] '
                  >
                    <BookImage
                      book={!screens.sm ? book.imageSM : book.imageLG}
                      handleImageLoad={handleImageLoad}
                    />

                    <ShouldRender
                      check={screens.lg && !isSchool && book.slug === false}
                    >
                      <div className='absolute bottom-[15%] left-[3%]'>
                        <ButtonDefault
                          size={screens.lg ? 4 : 1}
                          rounded={true}
                          variant={ButtonVariants.WHITE}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (window !== undefined && book?.webFlowLink) {
                              window.open(book.webFlowLink, '_blank');
                            }
                            if (window !== undefined && book?.link) {
                              router.push(book.link);
                            }
                            const eventParams: EventDetails = {
                              event_name: 'banner_view',
                              source: getSourceValue(router.pathname),
                              source_type: '',
                              source_id: null,
                              sub_source: null,
                              sub_source_id: null,
                              unit: 'boolean',
                              value: 1,
                            };

                            logEvent({
                              eventDetails: eventParams,
                              userData: userData,
                              pathname: router.pathname,
                            });
                          }}
                          className='w-min '
                        >
                          <span className='flex justify-center text-xs gap-x-2 xl:text-base px-2 lg:px-4 text-black whitespace-nowrap'>
                            Learn more
                          </span>
                        </ButtonDefault>
                      </div>
                    </ShouldRender>

                    {/* <ShouldRender check={screens.sm}>
                        <div className="absolute inset-0 before:content-[''] before:absolute before:inset-0 before:bg-gradient-to-r from-bgColor via-transparent w-[50%] to-transparent"></div>
                      </ShouldRender>
                      <ShouldRender check={!screens.sm}>
                        <div className="absolute inset-0 before:content-[''] before:absolute before:inset-0 before:bg-gradient-to-t from-bgColor via-transparent  md:w-[50%] to-transparent"></div>
                      </ShouldRender> */}
                  </div>
                </div>
              ))}
              {/* {books.map((book) => (
              <div key={book.id} className='w-full text-fadedWhite h-auto'>
                <div className='flex flex-col w-full gap-y-5 md:gap-y-0 md:flex-row'>
                  {/* <ShouldRender check={screens.sm}>
                    <div className='flex items-end w-6/12'>
                      <div className='  flex flex-col gap-y-5 xl:py-10 px-14 justify-between'>
                        <div className='flex flex-col gap-y-2 lg:gap-y-5  py-5'>
                          <p className=' text-center text-xl lg:text-2xl xl:text-4xl md:text-start '>
                            {hoveredBookId !== null
                              ? books[hoveredBookId]?.desc
                              : ''}
                          </p>
                          <span className='text-base lg:text-lg xl:text-2xl text-center md:text-start  xl:leading-10 flex   font-medium text-[#BABABA] '>
                            {books[hoveredBookId]?.name}
                          </span>
                          <div className='flex xs:flex-row gap-4 w-full lg:pt-7'>
                            <ButtonDefault
                              size={screens.lg ? 4 : 1}
                              rounded={true}
                              variant={ButtonVariants.WHITE}
                              onClick={() => {
                                // if (window !== undefined && book?.webFlowLink) {
                                //   window.open(book.webFlowLink, '_blank');
                                // }
                                router.push(book.webFlowLink);
                              }}
                              className='w-min '
                            >
                              <span className='flex justify-center text-xs gap-x-2 xl:text-base px-2 lg:px-4 text-black whitespace-nowrap'>
                                Learn more
                              </span>
                            </ButtonDefault>
                            {/* <ButtonDefault
                              size={screens.lg ? 4 : 1}
                              rounded={true}
                              variant={ButtonVariants.GRAY}
                              onClick={() => router.push('/product')}
                              className='text-fadedWhite  w-min font-bold p-1 text-xs xl:text-base whitespace-nowrap'
                            >
                              <span className='w-full  px-4'>Read later</span>
                            </ButtonDefault> */}
              {/* </div>
                        </div>
                      </div>
                    </div>
                  </ShouldRender> */}
              {/* <div className='flex justify-center  w-full'>
                    <div
                      onClick={() => {
                        if (!screens.md) {
                          if (window !== undefined && book?.webFlowLink) {
                            window.open(book.webFlowLink, '_blank');
                          }
                          if (window !== undefined && book?.link) {
                            router.push(book.link);
                          }
                        }
                      }}
                      className='flex relative w-full md:w-full items-center flex-shrink-0 '
                    >
                      <Image
                        src={!screens.sm ? book.imageSM : book.imageLG}
                        alt='BookImage'
                        layout={screens.sm ? 'responsive' : ''}
                        width={screens.sm ? 720 : 460}
                        height={screens.sm ? 500 : 360}
                        className='md:object-contain md:rounded-2xl'
                      />
                      <ShouldRender check={screens.md}>
                        <div className='absolute bottom-[15%] left-[3%]'>
                          <ButtonDefault
                            size={screens.lg ? 4 : 1}
                            rounded={true}
                            variant={ButtonVariants.WHITE}
                            onClick={() => {
                              if (window !== undefined && book?.webFlowLink) {
                                window.open(book.webFlowLink, '_blank');
                              }
                              if (window !== undefined && book?.link) {
                                router.push(book.link);
                              }
                            }}
                            className='w-min '
                          >
                            <span className='flex justify-center text-xs gap-x-2 xl:text-base px-2 lg:px-4 text-black whitespace-nowrap'>
                              Learn more
                            </span>
                          </ButtonDefault>
                        </div>
                      </ShouldRender>

                      {/* <ShouldRender check={screens.sm}>
                        <div className="absolute inset-0 before:content-[''] before:absolute before:inset-0 before:bg-gradient-to-r from-bgColor via-transparent w-[50%] to-transparent"></div>
                      </ShouldRender>
                      <ShouldRender check={!screens.sm}>
                        <div className="absolute inset-0 before:content-[''] before:absolute before:inset-0 before:bg-gradient-to-t from-bgColor via-transparent  md:w-[50%] to-transparent"></div>
                      </ShouldRender> */}
              {/* </div>
                  </div>
                </div>
              </div> */}
              {/* ))}  */}
            </Carousel>
          </ShouldRender>
          <ShouldRender check={!books?.length || !allImagesLoaded}>
            <div className='carousel-skeleton-container'>
              {/* Skeleton Wrapper */}
              <div className='relative overflow-hidden'>
                {/* Skeleton for carousel image */}
                <Skeleton.Button
                  active={true}
                  className='carousel-skeleton-image  lg:rounded-lg w-full h-[250px] lg:h-[350px]  xl:h-[430px]'
                ></Skeleton.Button>

                {/* Skeleton for Learn More Button */}
                <div className='absolute top-[25%] w-full left-[3%]'>
                  <Skeleton.Button
                    style={{
                      width: screens.lg ? '15vw' : '45vw',
                      height: screens.lg ? '25px' : '15px',
                    }}
                    active
                    size='large'
                    className='rounded-md lg:rounded-lg'
                  />
                </div>
                <div className='absolute top-[35%] w-full left-[3%]'>
                  <Skeleton.Button
                    style={{
                      width: screens.lg ? '25vw' : '55vw',
                      height: screens.lg ? '25px' : '15px',
                    }}
                    active
                    size='large'
                    className='rounded-lg'
                  />
                </div>
                <div className='absolute bottom-[15%] left-[3%]'>
                  <Skeleton.Button
                    style={{
                      width: screens.lg ? '150px' : '100px',
                      height: screens.lg ? '40px' : '25px',
                    }}
                    active
                    size='large'
                    className='rounded-md lg:rounded-lg'
                  />
                </div>

                {/* Left & Right Arrow Skeleton */}
              </div>
            </div>
          </ShouldRender>
        </div>
        <ShouldRender check={screens.lg && books?.length > 1}>
          <div className='flex absolute cursor-pointer -right-4 bottom-[50%] transform h-28 -translate-y-[-50%] lg:translate-y-[50%] items-center next-arrow'>
            <button
              name='next'
              aria-label='Go next'
              onClick={() => {
                handleMouseEnter(hoveredBookId + 1);
              }}
              className='z-10 w-8 h-8 xl:w-9 xl:h-9 rounded-full  disabled:cursor-not-allowed    flex cursor-pointer justify-center bg-gray-500 bg-opacity-40 items-center text-fadedWhite   backdrop-filter backdrop-blur-md   '
            >
              <ChevronRightIcon className=' w-4 h-4 xl:w-6 xl:h-6 ' />
            </button>
          </div>
        </ShouldRender>
      </div>
    </div>
  );
};

export default HeroSection;
