import { Popover } from 'antd';
import React from 'react';

interface ParseProps {
  text: string;
  maxWords?: number;
  className?: string;
  isPopOver?: boolean;
}

const ParserV2: React.FC<ParseProps> = ({
  text,
  maxWords,
  className,
  isPopOver = false,
}) => {
  let parsedText = text;
  let ellipsis = '';

  if (maxWords !== undefined) {
    const words = text?.split(' ');
    if (words?.length > maxWords) {
      parsedText = words?.slice(0, maxWords)?.join(' ');
      ellipsis = '...';
    }
  }

  const content = (
    <div>
      <p>{text}</p>
    </div>
  );

  return (
    <React.Fragment>
      {ellipsis && isPopOver ? (
        <Popover content={content}>
          <p className={className}>
            {parsedText}
            {ellipsis}
          </p>
        </Popover>
      ) : (
        <p className={className}>
          {parsedText} {ellipsis}
        </p>
      )}
    </React.Fragment>
  );
};

export default ParserV2;
