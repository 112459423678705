import { Badge, Skeleton, Tag } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

import ParserV2 from '../shared/basic/Parser/ParserV2';
import { ShouldRender } from '../shared/basic/ShouldRender';
import { notify } from '../shared/notify';
import HomepageSlider from '../V2/HomePage/HomepageSlider';
import { Endpoints } from '../../network';
import { generateUrlForImage } from '../../pages/books/[bookId]';
import { getAllBookListApi } from '../../services/Library/Books';
import { PaginationProps } from '../../utils/interfaces/Orders';
import { BookDummy } from '../../../assets';

interface Props {
  categoryName: string;
  categoryId: string | null;
  search?: string;
  bookType?: string;
  isHomePage?: boolean;
  categoryIdFilter?: string | null;
  order?: string;
  featured?: boolean;
}

const PaginatedBookSlider: React.FC<Props> = ({
  categoryId,
  categoryName,
  search,
  isHomePage = false,
  bookType,
  categoryIdFilter,
  order,
  featured,
}) => {
  const [books, setBooks] = useState<
    {
      _id: string;
      coverImageUrl: string;
      title: string;
      externalLink: string;
      type: string;
      slug: string;
      totalPages: number;
      isExternalLink: boolean;
    }[]
  >([]);
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState(false);
  const [isPaginateShift, setIsPaginateShift] = useState(true);
  const [paginationData, setPaginationData] = useState<PaginationProps>();
  const screens = useBreakpoint();
  const router = useRouter();

  const handleGetAllBooks = async () => {
    try {
      setLoading(true);
      const response = await getAllBookListApi({
        page: page.toString(),
        categoriesId: categoryId,
        keyword: search,
        ...(isHomePage && (bookType ? { type: bookType } : { type: 'long' })),
        ...(categoryIdFilter && { categoryId: categoryIdFilter as string }),
        ...(order && { order }),
        ...(featured && { featured }),
      });
      const { data } = response;

      const formattedBooks = data?.map((book: any) => ({
        _id: book?._id,
        coverImageUrl: generateUrlForImage(
          `${process.env.NEXT_PUBLIC_API_READER_URL}/`,
          `${Endpoints.getCoverImagesForBooks}/`,
          `${book?._id}`
        ),
        title: book?.title,
        externalLink: book?.externalLink,
        slug: book?.slug,
        type: book?.type,
        isExternalLink: book?.isExternalLink,
        totalPages: book?.totalPages,
      }));

      setPaginationData({
        current_page: response?.metadata?.page,
        per_page: response?.metadata?.limit,
        total: response?.metadata?.total,
      });

      if (page === 1) {
        setBooks(formattedBooks);
      } else {
        setBooks((prevData) => [...prevData, ...formattedBooks]);
      }
    } catch (error: any) {
      notify(error, 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetAllBooks();
  }, [page, categoryId, search, categoryIdFilter]);

  const handlePagination = () => {
    if (paginationData && books.length < paginationData?.total)
      setPage((prevPage) => prevPage + 1);
  };

  return (
    <>
      <HomepageSlider
        heading={categoryName}
        onPagination={handlePagination}
        setIsPaginateShift={setIsPaginateShift}
        isPaginateShift={isPaginateShift}
        loading={!!books.length}
      >
        {!loading
          ? books?.map((item, index) => {
              return (
                <span
                  key={index}
                  className='group cursor-pointer p-1 md:p-1.5  no-select'
                >
                  <ShouldRender check={item.type === 'short'}>
                    {/* <Link key={index} href={`/books/${item.slug}`}> */}
                    <span
                      onClick={() => {
                        router.push(
                          `/books/${item.slug}?productId=${item?._id}&swp=${item?.isExternalLink}`
                        );
                      }}
                    >
                      <Badge.Ribbon
                        placement='start'
                        className='customRibbon text-white'
                        text={<div className='text-xs font-medium'>Free</div>}
                        style={{ color: 'white' }}
                      >
                        <Image
                          src={item.coverImageUrl || BookDummy}
                          alt={item.title}
                          width={200}
                          height={200}
                          className='rounded-sm md:rounded-md transition-transform transform hover:scale-105 duration-500 max-w-[120px] lg:max-w-[140px] xl:max-w-[200px]'
                        />
                      </Badge.Ribbon>
                      <div className=' mt-2'>
                        <span className='text-textGrayColor text-xs md:text-sm  '>
                          {item?.totalPages} Pages
                        </span>

                        <div className='text-white text-sm xl:text-base font-medium w-full max-w-[120px] lg:max-w-[140px] xl:max-w-[200px]  '>
                          <ParserV2
                            isPopOver={false}
                            text={item?.title}
                            maxWords={10}
                          />
                        </div>
                      </div>
                      {/* </Link> */}
                    </span>
                  </ShouldRender>
                  <ShouldRender check={item.type === 'comingSoon'}>
                    {/* <Link key={index} href={`/books/${item.slug}`}> */}
                    <span
                      onClick={() => {
                        router.push(
                          `/books/${item.slug}?productId=${item?._id}&swp=${item?.isExternalLink}&cS`
                        );
                      }}
                    >
                      <span className='max-w-[120px]  lg:max-w-[140px] xl:max-w-[200px] relative'>
                        <Image
                          src={item.coverImageUrl || BookDummy}
                          alt={item.title}
                          width={200}
                          height={200}
                          className='rounded-sm md:rounded-md transition-transform transform hover:scale-105 duration-500 max-w-[120px] lg:max-w-[140px] xl:max-w-[200px]'
                        />
                        <Tag
                          color='#3E0A6E'
                          className='text-[#EAD3FF] absolute bottom-1 xl:bottom-2 left-2 transform  font-semibold md:py-0.5 text-[10px] md:text-xs  rounded-[4px]'
                        >
                          Coming Soon
                        </Tag>
                      </span>
                      <div className=' mt-2'>
                        <span className='text-textGrayColor text-xs md:text-sm  '>
                          {item?.totalPages} Pages
                        </span>

                        <div className='text-white text-sm xl:text-base font-medium w-full max-w-[120px] lg:max-w-[140px] xl:max-w-[200px]  '>
                          <ParserV2
                            isPopOver={false}
                            text={item?.title}
                            maxWords={10}
                          />
                        </div>
                      </div>
                      {/* </Link> */}
                    </span>
                  </ShouldRender>

                  <ShouldRender check={item.type === 'long'}>
                    <span
                      onClick={() => {
                        if (!item?.isExternalLink) {
                          if (window !== undefined && item?.externalLink) {
                            window.open(item?.externalLink, '_blank');
                          }
                        } else {
                          router.push(
                            `/books/${item.slug}?productId=${item?._id}`
                          );
                        }
                      }}
                    >
                      {/* <Link
                        key={index}
                        href={`/books/${item.slug}?productId=${item?._id}`}
                      > */}
                      <Image
                        src={item.coverImageUrl || BookDummy}
                        alt={item.title}
                        width={200}
                        height={200}
                        className='rounded-sm md:rounded-md transition-transform transform hover:scale-105 duration-500 max-w-[120px] lg:max-w-[140px] xl:max-w-[200px]'
                      />
                      <div className=' mt-2'>
                        <span className='text-textGrayColor text-xs md:text-sm  '>
                          {item?.totalPages} Pages
                        </span>

                        <div className='text-white text-sm xl:text-base font-medium w-full max-w-[120px] lg:max-w-[140px] xl:max-w-[200px]'>
                          {item?.title}
                        </div>
                      </div>
                      {/* </Link> */}
                    </span>
                  </ShouldRender>
                </span>
              );
            })
          : Array.from({ length: 10 }).map((_, index) => (
              <span
                key={index}
                className='group cursor-pointer flex flex-col gap-y-1 p-1 md:p-1.5 bg-transparent no-select '
              >
                <Skeleton.Button
                  style={{
                    width: '200px',
                    height: screens.xl ? '250px' : '150px',
                  }}
                  active={true}
                  className='rounded-sm md:rounded-md  transition-transform transform  duration-500 max-w-[120px] lg:max-w-[140px] xl:max-w-[200px]'
                />
                <Skeleton.Button
                  style={{
                    width: screens.xl ? '100px' : screens.lg ? '50px' : '20px',
                    height: '8px',
                    fontSize: '7px',
                  }}
                  active={true}
                  className='rounded-sm  transition-transform transform  duration-500 '
                />
                <Skeleton.Button
                  style={{
                    // width: screens.xl ? '100px' : screens.lg ? '50px' : '20px',
                    height: '12px',
                    fontSize: '7px',
                  }}
                  active={true}
                  className='rounded-sm w-full  -translate-y-1 transition-transform transform  duration-500 max-w-[120px] lg:max-w-[140px] xl:max-w-[220px]'
                />
              </span>
            ))}
        <ShouldRender check={loading || isPaginateShift}>
          {Array.from({ length: 10 }).map((_, index) => (
            <span
              key={index}
              className='group cursor-pointer flex flex-col gap-y-1 p-1 md:p-1.5 bg-transparent no-select '
            >
              <Skeleton.Button
                style={{
                  width: '200px',
                  height: screens.xl ? '250px' : '150px',
                }}
                active={true}
                className='rounded-sm md:rounded-md  transition-transform transform  duration-500 max-w-[120px] lg:max-w-[140px] xl:max-w-[200px]'
              />
              <Skeleton.Button
                style={{
                  width: screens.xl ? '100px' : screens.lg ? '50px' : '20px',
                  height: '8px',
                  fontSize: '7px',
                }}
                active={true}
                className='rounded-sm  transition-transform transform  duration-500 '
              />
              <Skeleton.Button
                style={{
                  // width: screens.xl ? '100px' : screens.lg ? '50px' : '20px',
                  height: '12px',
                  fontSize: '7px',
                }}
                active={true}
                className='rounded-sm w-full  -translate-y-1 transition-transform transform  duration-500 max-w-[120px] lg:max-w-[140px] xl:max-w-[220px]'
              />
            </span>
          ))}
        </ShouldRender>
      </HomepageSlider>
    </>
  );
};

export default PaginatedBookSlider;
